import { Column } from "@ant-design/plots";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "../../components/Layout/TopBar";
import {
  getAllBloodCount,
  getBlogCount,
  getBloodNeedCount,
  getDonorCount,
  getFulfilledBloodCount,
  getNonVerifiedDonor,
  getPageCount,
  getStoriesCount,
  getUserCount,
  getVerifiedDonor,
} from "../../features/dashboardSlice";
import css from "../../styles/HomePage/homePage.module.scss";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPageCount({ type: "page" }));
    dispatch(getStoriesCount({ type: "story" }));
    dispatch(getBlogCount({ type: "blog" }));
    dispatch(getVerifiedDonor({ status: "Verified" }));
    dispatch(getNonVerifiedDonor({ status: "Pending" }));
    dispatch(getDonorCount());
    dispatch(getBloodNeedCount());
    dispatch(getFulfilledBloodCount({ isFulFilled: true }));
    dispatch(getUserCount({}));
    dispatch(
      getUserCount({
        filter: { organizerProfile: { status: "Verified" } },
        organizerCount: true,
      })
    );
    dispatch(
      getUserCount({
        filter: {
          phoneVerified: false,
          idProfile: {
            status: "Pending",
          },
        },
        unverifiedUser: true,
      })
    );

    dispatch(getAllBloodCount());
    dispatch(
      getUserCount({ filter: { phoneVerified: true }, phoneUserCount: true })
    );
    dispatch(
      getUserCount({
        filter: {
          idProfile: {
            status: "Verified",
          },
        },
        idUserCount: true,
      })
    );
  }, [dispatch]);

  const values = useSelector((state) => state.dashboard);

  // Data for blood group
  const bloods = values?.allblood;

  const remaining = [
    {
      action: "A+",
      count: 0,
    },
    {
      action: "B+",
      count: 0,
    },
    {
      action: "O+",
      count: 0,
    },
    {
      action: "AB+",
      count: 0,
    },
    {
      action: "A-",
      count: 0,
    },
    {
      action: "B-",
      count: 0,
    },
    {
      action: "O-",
      count: 0,
    },
    {
      action: "AB-",
      count: 0,
    },
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    let data = [];
    data =
      bloods?.length > 0
        ? bloods?.map((value) => {
            return {
              action: value?.blood_group_name,
              count: parseInt(value?.user_count),
            };
          })
        : [];

    remaining?.forEach((item) => {
      const existingItem =
        data?.length > 0 &&
        data?.find((dataItem) => dataItem?.action === item?.action);

      if (!existingItem) {
        data?.push(item);
      }
    });

    setData(data);
  }, [bloods]);

  const commonColumnConfig = {
    xField: "category",
    yField: "count",
    label: {
      position: "middle",
      style: {
        fontSize: 16,
        fill: "#fff",
      },
    },
    columnWidthRatio: 0.5,
  };

  const colorPalette = ["#1e90ff", "#20c997", "#ffc107", "#FF6B6B", "#FFD700"];

  const config = {
    data,
    columnWidthRatio: 0.4,
    xField: "action",
    yField: "count",
    xAxis: true,
    forceFit: true,
    label: {
      formatter: (count) => count.action,
      style: {
        fontWeight: "bold",
        fontSize: 22,
      },
      position: "middle",
    },
    columnStyle: {
      cursor: "pointer",
    },
    color: (data) => colorPalette[data?.index % colorPalette.length],
  };

  // Config for each chart with new color palette
  const userCountConfig = {
    data: [
      { category: "Id Verified", count: values?.idVerifiedUsers || 0 },
      { category: "Phone Verified", count: values?.phoneVerifiedUsers || 0 },
      { category: "Unverified", count: values?.unVerifiedUsers || 0 },
    ],
    ...commonColumnConfig,
    color: ({ category }) => {
      switch (category) {
        case "Id Verified":
          return "#1e90ff"; // Blue
        case "Phone Verified":
          return "#20c997"; // Green
        case "Unverified":
          return "#ffc107"; // Orange
        default:
          return "#6F6AF8"; // Default color if necessary
      }
    },
  };

  const donorCountConfig = {
    data: [
      { category: "Verified Donor", count: values?.verifiedDonor || 0 },
      { category: "Non-Verified Donor", count: values?.nonVerifiedDonor || 0 },
    ],
    ...commonColumnConfig,
    color: ({ category }) => {
      switch (category) {
        case "Verified Donor":
          return "#1e90ff"; // Blue
        case "Non-Verified Donor":
          return "#20c997"; // Green
        default:
          return "#6F6AF8"; // Default color if necessary
      }
    },
  };

  const bloodNeedConfig = {
    data: [
      { category: "Fulfilled", count: values?.fulfilledBlood || 0 },
      {
        category: "Unfulfilled",
        count: (values?.totalBloodNeeds || 0) - (values?.fulfilledBlood || 0),
      },
    ],
    ...commonColumnConfig,
    color: ({ category }) => {
      switch (category) {
        case "Fulfilled":
          return "#1e90ff"; // Blue
        case "Unfulfilled":
          return "#20c997"; // Green
        default:
          return "#6F6AF8"; // Default color if necessary
      }
    },
  };

  const storiesConfig = {
    data: [
      { category: "Blogs", count: values?.blogCount || 0 },
      { category: "Stories", count: values?.storyCount || 0 },
    ],
    ...commonColumnConfig,
    color: ({ category }) => {
      switch (category) {
        case "Blogs":
          return "#1e90ff"; // Blue
        case "Stories":
          return "#20c997"; // Green
        default:
          return "#6F6AF8"; // Default color if necessary
      }
    },
  };

  return (
    <div>
      <TopBar topic="Dashboard" />
      <div className={css.homePageContainer}>
        <div className={css.mainDiv}>
          {/* Blood Group Chart at the Top */}
          <div className={css.graphPart}>
            <Row gutter={[16, 16]} justify="center">
              <Col xs={24} className={css.barGraph}>
                <h3>User Count By Blood Group</h3>
                <Column {...config} />
              </Col>
            </Row>

            {/* Other charts below */}
            <div className={css.gridContainer}>
              <div className={css.gridItem}>
                <h3>Total Users</h3>
                <Column {...userCountConfig} />
              </div>

              <div className={css.gridItem}>
                <h3>Total Donors</h3>
                <Column {...donorCountConfig} />
              </div>

              <div className={css.gridItem}>
                <h3>Total Blood Need</h3>
                <Column {...bloodNeedConfig} />
              </div>

              <div className={css.gridItem}>
                <h3>Total Stories and Blogs</h3>
                <Column {...storiesConfig} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
