import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
const qs = require("qs");

const initialState = {
  partners: [],
  loading: false,
  error: "",
};

// TODO: get Partners

export const getPartners = createAsyncThunk(
  "getPartners",
  async ({ params, filter, sort }) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        name: { $eqi: filter?.name },
        email: filter?.email,
        location: { eqi: filter?.location },
        phone: { $eqi: filter?.phone },
        type: { $eqi: filter?.type },
      },
      sort: sort ? sort : ["updatedAt:asc"],
    });
    const res = await api.get(`${BASEURL}/partners?populate=*&${query}`);
    return res.data;
  }
);

// TODO: post Partners
export const postPartners = createAsyncThunk(
  "postPartners",
  async (data, thunkApi) => {
    const res = await api.post(`${BASEURL}/partners`, data);
    thunkApi.dispatch(getPartners({ params: { page: 1, pageSize: 8 } }));
    return res.data;
  }
);

// TODO: update Partners

export const updatePartners = createAsyncThunk(
  "updatePartners",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/partners/${id}`, formData);
    thunkApi.dispatch(getPartners({ params: { page: 1, pageSize: 8 } }));
    return res.data;
  }
);

// TODO: delete Partners

export const deletePartners = createAsyncThunk(
  "deletePartners",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/partners/${id}`);
    thunkApi.dispatch(getPartners({ params: { page: 1, pageSize: 8 } }));
    return res.data;
  }
);

const partnerSlice = createSlice({
  name: "partners",
  initialState,
  extraReducers: {
    // TODO: get
    [getPartners.pending]: (state, action) => {
      state.loading = true;
    },
    [getPartners.fulfilled]: (state, action) => {
      state.loading = false;
      state.partners = action.payload;
    },
    [getPartners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: post
    [postPartners.pending]: (state, action) => {
      state.loading = true;
    },
    [postPartners.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Partner Added",
        confirmButtonColor: "#4BB543",
        text: "Partner is successfully added",
      });
    },
    [postPartners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Update
    [updatePartners.pending]: (state, action) => {
      state.loading = true;
    },
    [updatePartners.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Partner Updated",
        confirmButtonColor: "#4BB543",
        text: "Partner is successfully updated",
      });
    },
    [updatePartners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },

    // TODO: Delete
    [deletePartners.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePartners.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Partner Deleted",
        confirmButtonColor: "#4BB543",
        text: "Partner is successfully deleted",
      });
    },
    [deletePartners.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        confirmButtonColor: "#B00020",
        text: action.error.message,
      });
    },
  },
});

export default partnerSlice.reducer;
