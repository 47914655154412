import { Button, Col, Dropdown, Popover, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiAddToQueue } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterSearch from "../../components/common/FilterSearch";
import Filter from "../../components/Layout/ReviewFilter";
import TopBar from "../../components/Layout/TopBar";
import SendEmail from "../../components/SendEmail/SendEmail";
import { getFeedback } from "../../features/feedbackSlice";
import css from "../../styles/Users/users.module.scss";

const Feedback = () => {
  const navigate = useNavigate();

  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState();
  const [sort, setSort] = useState();

  useEffect(() => {
    dispatch(getFeedback({ params, filter: filteredData, sort }));
  }, [dispatch, filteredData, params, sort]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [emailBox, setEmailBox] = useState(false);

  // bring user data from store
  let feedbackData = useSelector((state) => state.feedback.feedback);
  let loading = useSelector((state) => state.feedback.loading);

  let dataSource = feedbackData?.data?.map((f, i) => ({
    id: f?.id,
    key: f?.attributes?.email,
    name: f?.attributes?.name,
    phone: f?.attributes?.phone,
    subject: f?.attributes?.subject,
    email: f?.attributes?.email,
    message: f?.attributes?.message,
    createdAt: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    topic: f?.attributes?.topic || "-",
  }));

  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = ["name", "email", "phone", "message", "topic", "date"];

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredData(filters);
    if (sorter?.order) {
      setSort(
        `${sorter?.columnKey}:${sorter?.order === "ascend" ? "asc" : "desc"}`
      );
    }
  };

  // data columns
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },

    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Topic",
      dataIndex: "topic",
      key: "topic",
      filters: [
        {
          text: "Volunteering",
          value: "Volunteering",
        },
        {
          text: "Blood Donor",
          value: "Blood Donor",
        },

        {
          text: "Partner",
          value: "Partner",
        },
        {
          text: "Suggestion",
          value: "Suggestion",
        },
      ],
      filteredValue: filteredData?.topic || null,
      onFilter: (value, record) => true,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.deactivate,
    }),
  };

  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  const start = (key) => {
    setTimeout(() => {
      setSelectedRowKeys([]);
      alert(key);
    }, 1000);
  };

  const items = [
    {
      label: (
        <Popover
          open={emailBox}
          content={
            <SendEmail
              emails={selectedRowKeys?.map((email) => email)}
              open={setEmailBox}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          }
          showArrow={false}
          placement="topLeft"
          autoAdjustOverflow={true}
          destroyTooltipOnHide={true}
          overlayStyle={{
            width: "30%",
          }}
        >
          <span onClick={() => setEmailBox(true)}>Send Email</span>
        </Popover>
      ),
      key: "1",
    },
  ];
  const menu = {
    items,
  };

  return (
    <div>
      <TopBar topic={"Feedbacks"} />
      <div className={css.userContent}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col>

          {/* search filter and add new */}
          <FilterSearch
            showFilter={true}
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Add Review"
            show={false}
            filterModal={filterModal}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
            icon={<BiAddToQueue className={css.filterIcon} />}
          />
        </Row>
        {/* table here */}

        <Table
          onChange={handleTableChange}
          scroll={{
            x: 1500,
          }}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          bordered
          loading={loading}
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: feedbackData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            responsive: true,
            showTitle: false,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
          // onRow={(row) => {
          //   return {
          //     onClick: () => {
          //       navigate(`/dashboard/feedback/${row?.id}`);
          //     },
          //   };
          // }}
        />
      </div>
    </div>
  );
};

export default Feedback;
