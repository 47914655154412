import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Popconfirm,
  Popover,
  Row,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { GoUnverified, GoVerified } from "react-icons/go";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddUsers from "../../components/Add/AddUsers";
import EditDots from "../../components/common/EditDots";
import FilterSearch from "../../components/common/FilterSearch";
import EditUser from "../../components/Edit/EditUser";
import BloodNeedCard from "../../components/Layout/BloodNeedCard";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import FilterUser from "../../components/Layout/FilterUser";
import TopBar from "../../components/Layout/TopBar";
import SendEmail from "../../components/SendEmail/SendEmail";
import {
  activateUser,
  blockUnblockUser,
  deactivateUser,
  deleteUser,
  getAllUserCounts,
  getSuspendedUserCounts,
  getThisMonthUsers,
  getUsers,
  getVerifiedUsers,
} from "../../features/userSlice";
import icon1 from "../../Images/BloodNeeds/allNeeds.svg";
import icon3 from "../../Images/BloodNeeds/completeNeeds.svg";
import {
  default as icon2,
  default as icon4,
} from "../../Images/BloodNeeds/verifiedNeeds.svg";
import editIcon from "../../Images/Users/editIcon.svg";
import noProfile from "../../Images/Users/userProfile.png";
import css from "../../styles/Users/users.module.scss";
import { limit } from "../../utils/truncatingString";

const Users = () => {
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });

  const [filterData, setFilterData] = useState();
  const [sort, setSort] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  // TODO: get users List
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUserCounts());
    dispatch(getSuspendedUserCounts());
    dispatch(getThisMonthUsers());
    dispatch(getVerifiedUsers());
  }, [dispatch]);
  // =====================

  useEffect(() => {
    dispatch(getUsers({ params, filter: filterData, sort }));
  }, [dispatch, filterData, params, sort]);

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deactivate, setDeactivateModal] = useState(false);
  const [activate, setActivateModal] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    setEditModal(false);
    setIsModalOpen(false);
    setFilterModal(false);
  };

  // filter Modal

  const [edit, setEdit] = useState({
    key: 0,
  });

  // bring user data from store
  let usersData = useSelector((state) => state.users.users);

  const loadingUser = useSelector((state) => state.users.loading);
  const values = useSelector((state) => state.users.cardValues);

  // handle search
  const [search, setSearch] = useState("");
  const [emailBox, setEmailBox] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilterData(filters);
    if (sorter?.order) {
      setSort(
        `${sorter?.columnKey}:${sorter?.order === "ascend" ? "asc" : "desc"}`
      );
    } else {
      setSort();
    }
  };

  const fields = [
    "username",
    "bloodGroup",
    "gender",
    "phone",
    "age",
    "location",
  ];

  let dataSource = usersData?.map((f, i) => ({
    key: f,
    id: f?.id,
    firstName: `${f?.firstName} ${f?.lastName}`,
    avatarUrl: f?.avatar?.url || f?.avatarUrl || noProfile,
    bloodGroup: f?.bloodGroup?.name || "-",
    gender: f?.gender || "-",
    donorProfile: f?.donorProfile,
    age: f?.donorProfile?.dob,
    provider: f?.provider,
    email: limit(f?.email, 30),
    phone: f?.phone,
    deactivate: f?.deactivate,
    idVerified: f?.idProfile?.status === "Verified" ? true : false,
    phoneVerified: f?.phoneVerified,
    blocked: f?.blocked,
    location: `${f?.currentAddress?.city} -${f?.currentAddress?.wardNo} , ${f?.currentAddress?.district}`,
    createdAt: moment.utc(f?.createdAt).format("YYYY-MM-DD"),
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  // data columns
  const columns = [
    {
      title: "Name",
      key: "firstName",
      width: 400,
      render: (row) => {
        return (
          <div
            className={css.profileImage}
            onClick={() => navigate(`${row.id}`)}
          >
            <Avatar src={row?.avatarUrl} />
            <span>{row?.firstName}</span>
          </div>
        );
      },
      // Add sorter for the Name column
      sorter: true,
    },
    {
      title: "Blood Group",
      dataIndex: "bloodGroup",
      key: "bloodGroup",
      filters: [
        {
          text: "A+",
          value: "A+",
        },
        {
          text: "B+",
          value: "B+",
        },
        {
          text: "O+",
          value: "O+",
        },
        {
          text: "AB+",
          value: "AB+",
        },
        {
          text: "A-",
          value: "A-",
        },
        {
          text: "B-",
          value: "B-",
        },
        {
          text: "O-",
          value: "O-",
        },
        {
          text: "AB-",
          value: "AB-",
        },
      ],
      filteredValue: filterData?.bloodGroup || null, // Persist filter state
      onFilter: (value, record) => true, // No filtering logic needed here, just return true    },
    },
    {
      title: "Provider",
      key: "provider",
      width: "15%",
      dataIndex: "provider",
      filters: [
        {
          text: "Google",
          value: "google",
        },
        {
          text: "Facebook",
          value: "facebook",
        },
        {
          text: "Apple",
          value: "apple",
        },
        {
          text: "Local",
          value: "local",
        },
      ],
      filteredValue: filterData?.provider || null, // Persist filter state
      onFilter: (value, record) => true, // No filtering logic needed here, just return true    },    },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "15%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Join Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true, // Sorting function for dates
      sortDirections: ["ascend", "descend"], // Ascending and descending order
    },
    {
      title: "Phone Verified",
      key: "phoneVerified",
      render: (row) => {
        return (
          <div>
            {row?.phoneVerified ? (
              <GoVerified style={{ color: "#296DE5", fontSize: "1.02rem" }} />
            ) : (
              <GoUnverified style={{ color: "#F52A3B", fontSize: "1.02rem" }} />
            )}
          </div>
        );
      },
      filters: [
        {
          text: "Verified",
          value: true,
        },
        {
          text: "UnVerified",
          value: false,
        },
      ],
      filteredValue: filterData?.phoneVerified || null,
      onFilter: (value, record) => true,
    },
    {
      title: "ID Verified",
      key: "idVerified",
      render: (row) => {
        return (
          <div>
            {row?.idVerified ? (
              <GoVerified style={{ color: "#296DE5", fontSize: "1.02rem" }} />
            ) : (
              <GoUnverified style={{ color: "#F52A3B", fontSize: "1.02rem" }} />
            )}
          </div>
        );
      },
      filters: [
        {
          text: "Verified",
          value: true,
        },
        {
          text: "UnVerified",
          value: false,
        },
      ],
      filteredValue: filterData?.idVerified || null,
      onFilter: (value, record) => true,
    },

    {
      title: "Suspend",
      key: "blocked",
      width: 100,
      render: (row) => {
        return (
          <Popconfirm
            overlayClassName={css.pop}
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={() =>
              dispatch(
                blockUnblockUser({
                  data: { blocked: !row?.blocked },
                  row: { row },
                })
              )
            }
          >
            <Switch checked={row?.blocked} />
          </Popconfirm>
        );
      },
      filters: [
        {
          text: "Suspended",
          value: true,
        },
        {
          text: "NonSuspended",
          value: false,
        },
      ],
      filteredValue: filterData?.blocked || null,
      onFilter: (value, record) => true,
    },

    {
      title: "Action",
      width: 100,
      render: (row) => {
        return (
          <EditDots
            from="users"
            icon={editIcon}
            setEdit={setEdit}
            row={row}
            edit={edit}
            deactivate={row?.deactivate}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
            setDeactivateModal={setDeactivateModal}
            setActivateModal={setActivateModal}
            changePw={<MdOutlineModeEditOutline />}
          />
        );
      },
    },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.deactivate,
    }),
  };

  const [hasSelected, setHasSelected] = useState(false);

  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  /* dropdown action */
  const items = [
    {
      label: (
        <Popover
          open={emailBox}
          content={
            <SendEmail
              emails={selectedRowKeys?.map((f) => f?.email)}
              open={setEmailBox}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          }
          showArrow={false}
          placement="topLeft"
          autoAdjustOverflow={true}
          destroyTooltipOnHide={true}
          overlayStyle={{
            width: "30%",
          }}
        >
          <span onClick={() => setEmailBox(true)}>Send Email</span>
        </Popover>
      ),
      key: "1",
    },
    {
      label: <span onClick={() => setDeactivateModal(true)}>Deactivate</span>,
      key: "2",
    },
  ];
  const menu = {
    items,
  };

  return (
    <div>
      <TopBar topic={"Users"} />
      <div className={css.userContent}>
        <Row justify={"space-between"} className={css.cardContainer}>
          <Col span={5} className={css.card}>
            <BloodNeedCard
              value={values?.totalUsers}
              icon={icon1}
              title={"Total users"}
            />
          </Col>
          <Col span={5} className={css.card}>
            <BloodNeedCard
              value={values?.verifiedUsers}
              icon={icon2}
              title={"Verified users"}
            />
          </Col>
          <Col span={5} className={css.card}>
            <BloodNeedCard
              value={values?.usersThisMonth}
              icon={icon3}
              title={"This month users"}
            />
          </Col>
          <Col span={5} className={css.card}>
            <BloodNeedCard
              value={values?.suspendedUsers}
              icon={icon4}
              title={"Suspended users"}
            />
          </Col>
        </Row>

        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
        >
          <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col>

          {/* search filter and add new */}
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            show={false}
            filterModal={filterModal}
            content={
              <FilterUser
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilterData={setFilterData}
              />
            }
          />
        </Row>
        {/* table here */}
        <Table
          onChange={handleTableChange}
          scroll={{
            x: 1500,
          }}
          loading={loadingUser}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "40", "50"], // Options for page size
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: usersData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>

      {/* modal */}
      <Modal
        maskClosable={false}
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={true}
        closable={true}
        onCancel={handleCancel}
      >
        <AddUsers setIsModalOpen={setIsModalOpen} />
      </Modal>

      <Modal
        open={editModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        maskClosable={false}
        keyboard={true}
        onCancel={() => setEditModal(false)}
      >
        <EditUser setEditModal={setEditModal} id={edit?.key} form={form} />
      </Modal>

      <Modal
        title={"Deactivate Confirmation"}
        open={deactivate}
        footer={null}
        centered={true}
        maskClosable={false}
        keyboard={false}
        onCancel={() => setDeactivateModal(false)}
      >
        <DeleteConfirmation
          fromDeactivate={true}
          setDeleteModal={setDeactivateModal}
          id={
            selectedRowKeys?.length > 0
              ? selectedRowKeys?.map((f) => f?.id)
              : edit?.key
          }
          delete={deactivateUser}
          get={getUsers}
        />
      </Modal>

      <Modal
        title={"Activate Confirmation"}
        open={activate}
        footer={null}
        centered={true}
        maskClosable={false}
        keyboard={false}
        onCancel={() => setActivateModal(false)}
      >
        <DeleteConfirmation
          fromActivate={true}
          setDeleteModal={setActivateModal}
          id={
            selectedRowKeys?.length > 0
              ? selectedRowKeys?.map((f) => f?.id)
              : edit?.key
          }
          delete={activateUser}
          get={getUsers}
        />
      </Modal>

      {/* modal */}

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        footer={null}
        centered={true}
        keyboard={false}
        onCancel={() => setDeleteModal(false)}
        maskClosable={false}
      >
        <DeleteConfirmation
          fromDelete={true}
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          delete={deleteUser}
          pageSize={params.pageSize}
        />
      </Modal>
    </div>
  );
};

export default Users;
