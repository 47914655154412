import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
import { getBloodNeeds } from "./bloodNeedsSlice";

const initialState = {
  donorList: [],
  loading: false,
  error: "",
  donorDetail: "",
  totalDonor: [],
  cardValues: {
    activeDonors: 0,
    inActiveDonors: 0,
    verifiedDonors: 0,
  },
};

const qs = require("qs");

// TODO: get
export const getDonorList = createAsyncThunk(
  "getDonorList",
  async ({ params, filter, sort }, thunkApi) => {
    const query = qs.stringify({
      pagination: params,
      filters: {
        ...(filter?.status ? { status: filter?.status } : {}),
        user: {
          ...(filter?.provider
            ? {
                provider: {
                  $in: filter?.provider,
                },
              }
            : {}),
        },
        ...(filter?.bloodGroup
          ? {
              bloodGroup: {
                name: filter?.bloodGroup,
              },
            }
          : {}),
        ...(filter?.gender ? { gender: filter?.gender } : {}),
      },
      populate: [
        "user.currentAddress",
        "bloodGroup",
        "user.userSetting",
        "questionares",
      ],
      _sort: sort ? sort : ["updatedAt:asc"],
    });
    const res = await api.get(`${BASEURL}/donor-profiles?populate=*&${query}`);
    thunkApi.dispatch(getBloodNeeds({}));
    return res.data;
  }
);
// TODO:show total number in card as const
export const getAllDonorList = createAsyncThunk(
  "getAllDonorList",
  async (_, thunkApi) => {
    const res = await api.get(`${BASEURL}/donor-profiles`);
    thunkApi.dispatch(getBloodNeeds({}));
    return res.data;
  }
);

export const getActiveDonorCount = createAsyncThunk(
  "getActiveDonorCount",
  async () => {
    const query = qs.stringify({
      pagination: {
        limit: 1,
      },
      filters: {
        user: {
          userSetting: {
            availableForDonation: true,
          },
          donorProfile: {
            status: "Verified",
          },
        },
      },
    });
    const res = await api.get(`${BASEURL}/donor-profiles?${query}`);
    return res.data;
  }
);

export const getVerifiedDonors = createAsyncThunk(
  "getVerifiedDonors",
  async () => {
    const query = qs.stringify({
      pagination: {
        limit: 1,
      },
      filters: {
        user: {
          donorProfile: {
            status: "Verified",
          },
        },
      },
    });
    const res = await api.get(`${BASEURL}/donor-profiles?${query}`);
    return res.data;
  }
);

export const getDonorDetail = createAsyncThunk("getDonorDetail", async (id) => {
  const query = qs.stringify({
    populate: [
      "user.avatar",
      "user.followers.followedBy.avatar",
      "bloodGroup",
      "user.currentAddress",
      "user.badges.badge.image",
      "user.role",
      "attachments",
      "questionares",
      "user.referBy",
      "user.userSetting",
    ],
  });
  const res = await api.get(`${BASEURL}/donor-profiles/${id}?${query}`);
  return res.data;
});

// TODO: post DonorList
export const postDonorList = createAsyncThunk(
  "postDonorList",
  async (data, thunkApi) => {
    const res = await api.post(`${BASEURL}/donor-profiles`, data);
    thunkApi.dispatch(
      getDonorList({
        params: {
          page: 1,
          pageSize: 6,
        },
      })
    );
    return res.data;
  }
);

// TODO: update DonorList

export const updateDonorList = createAsyncThunk(
  "updateDonorList",
  async ({ formData, id }, thunkApi) => {
    const res = await api.put(`${BASEURL}/donor-profiles/${id}`, formData);
    thunkApi.dispatch(getDonorDetail(id));
    return res.data;
  }
);

// TODO: delete DonorList

export const deleteDonorList = createAsyncThunk(
  "deleteDonorList",
  async (id, thunkApi) => {
    const res = await api.delete(`${BASEURL}/donor-profiles/${id}`);
    thunkApi.dispatch(
      getDonorList({
        params: {
          page: 1,
          pageSize: 6,
        },
      })
    );
    return res.data;
  }
);

const donorListSlice = createSlice({
  name: "bloodNeeed",
  initialState,
  reducers: {},
  extraReducers: {
    // TODO: get
    [getDonorList.pending]: (state, action) => {
      state.loading = true;
    },
    [getDonorList.fulfilled]: (state, action) => {
      state.loading = false;
      state.donorList = action.payload;
    },
    [getDonorList.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },
    // TODO:total number of donor for card
    [getAllDonorList.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllDonorList.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalDonor = action.payload;
    },
    [getAllDonorList.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: get detail
    [getDonorDetail.pending]: (state, action) => {
      state.loading = true;
    },
    [getDonorDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.donorDetail = action.payload;
    },
    [getDonorDetail.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: get active donors
    [getActiveDonorCount.pending]: (state, action) => {
      state.loading = true;
    },
    [getActiveDonorCount.fulfilled]: (state, action) => {
      state.loading = false;
      state.cardValues.activeDonors = action.payload;
    },
    [getActiveDonorCount.rejected]: (state, action) => {
      state.loading = false;
    },

    // TODO: get verified donors
    [getVerifiedDonors.pending]: (state, action) => {
      state.loading = true;
    },
    [getVerifiedDonors.fulfilled]: (state, action) => {
      state.loading = false;
      state.cardValues.verifiedDonors = action.payload;
    },
    [getVerifiedDonors.rejected]: (state, action) => {
      state.loading = false;
    },

    // TODO: post
    [postDonorList.pending]: (state, action) => {
      state.loading = true;
    },
    [postDonorList.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Donor Added",
        confirmButtonColor: "#4BB543",
        text: "Donor is successfully added",
      });
    },
    [postDonorList.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: Update
    [updateDonorList.pending]: (state, action) => {
      state.loading = true;
    },
    [updateDonorList.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Donor Updated",
        confirmButtonColor: "#4BB543",
        text: "Donor is successfully updated",
      });
    },
    [updateDonorList.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },

    // TODO: Delete
    [deleteDonorList.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteDonorList.fulfilled]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Donor Deleted",
        confirmButtonColor: "#4BB543",
        text: "Donor is successfully deleted",
      });
    },
    [deleteDonorList.rejected]: (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    },
  },
});

export default donorListSlice.reducer;
