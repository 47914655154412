import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Modal,
  Popover,
  Row,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddTeam from "../../components/Add/AddTeam";
import EditDots from "../../components/common/EditDots";
import FilterSearch from "../../components/common/FilterSearch";
import EditUser from "../../components/Edit/EditUser";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import Filter from "../../components/Layout/FilterTeam";
import TopBar from "../../components/Layout/TopBar";
import SendEmail from "../../components/SendEmail/SendEmail";
import {
  activateUser,
  deactivateUser,
  deleteTeamMember,
  getTeamMember,
  getUsers,
} from "../../features/userSlice";
import editIcon from "../../Images/Users/editIcon.svg";
import noProfile from "../../Images/Users/userProfile.png";
import css from "../../styles/Users/users.module.scss";

const Team = () => {
  const [form] = Form.useForm();
  const [filteredData, setFilteredData] = useState();
  const [emailBox, setEmailBox] = useState(false);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  const navigate = useNavigate();
  // TODO: get users List
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getTeamMember({
        params,
        filter: filteredData,
      })
    );
  }, [dispatch]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deactivate, setDeactivateModal] = useState(false);
  const [activate, setActivateModal] = useState(false);

  // filter Modal

  const [edit, setEdit] = useState({
    key: 0,
  });

  // bring user data from store
  let usersData = useSelector((state) => state.users.users);

  const isLoading = useSelector((state) => state.users.loading);

  let dataSource = usersData?.map((u, i) => ({
    id: u?.id,
    key: u,
    imageUrl: u?.avatar?.url || u?.avatarUrl || noProfile,
    username: u?.username,
    role: u?.role?.name,
    email: u?.email,
    phone: u?.phone,
    deactivate: u?.deactivate,
    date: moment.utc(u?.createdAt).format("YYYY-MM-DD"),
  }));

  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const fields = ["username", "role", "email", "phone"];

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  // data columns
  const columns = [
    {
      title: "Name",
      key: "username",

      render: (row) => {
        return (
          <div
            className={css.profileImage}
            onClick={() => navigate(`${row?.id}`)}
          >
            <Avatar src={row?.imageUrl} />

            <span>{row?.username}</span>
          </div>
        );
      },
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: "25%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Join Date",
      dataIndex: "date",
      key: "date",
    },

    {
      title: "Action",
      render: (row) => {
        return (
          <EditDots
            icon={editIcon}
            setEdit={setEdit}
            row={row}
            edit={edit}
            setEditModal={setEditModal}
            deactivate={row?.deactivate}
            setDeactivateModal={setDeactivateModal}
            setActivateModal={setActivateModal}
            setDeleteModal={setDeleteModal}
            changePw={<MdOutlineModeEditOutline />}
          />
        );
      },
    },
  ];

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.deactivate,
    }),
  };
  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  /* dropdown action */
  const items = [
    {
      label: (
        <Popover
          open={emailBox}
          content={
            <SendEmail
              emails={selectedRowKeys?.map((f) => f?.email)}
              open={setEmailBox}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          }
          showArrow={false}
          placement="topLeft"
          autoAdjustOverflow={true}
          destroyTooltipOnHide={true}
          overlayStyle={{
            width: "30%",
          }}
        >
          <span onClick={() => setEmailBox(true)}>Send Email</span>
        </Popover>
      ),
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menu = {
    items,
  };

  return (
    <div>
      <TopBar topic={"Team"} />
      <div className={css.userContent}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col>

          {/* search filter and add new */}
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            show={true}
            title="Add Team Member"
            filterModal={filterModal}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
          />
        </Row>
        {/* table here */}

        <Table
          scroll={{
            x: 1500,
          }}
          loading={isLoading}
          rowSelection={rowSelection}
          dataSource={dataSource}
          bordered
          columns={columns}
          pagination={{
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: usersData?.length,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>

      {/* modal */}
      <Modal
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <AddTeam setIsModalOpen={setIsModalOpen} />
      </Modal>

      <Modal
        open={editModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={true}
        onCancel={() => setEditModal(false)}
      >
        <EditUser
          setEditModal={setEditModal}
          id={edit?.key}
          form={form}
          from="team"
        />
      </Modal>

      <Modal
        title={"Deactivate Confirmation"}
        open={deactivate}
        footer={null}
        centered={true}
        maskClosable={false}
        keyboard={false}
        onCancel={() => setDeactivateModal(false)}
      >
        <DeleteConfirmation
          from="team"
          fromDeactivate={true}
          setDeleteModal={setDeactivateModal}
          id={
            selectedRowKeys?.length > 0
              ? selectedRowKeys?.map((f) => f?.id)
              : edit?.key
          }
          delete={deactivateUser}
          get={getUsers}
        />
      </Modal>

      <Modal
        title={"Activate Confirmation"}
        open={activate}
        footer={null}
        centered={true}
        maskClosable={false}
        keyboard={false}
        onCancel={() => setActivateModal(false)}
      >
        <DeleteConfirmation
          fromActivate={true}
          from="team"
          setDeleteModal={setActivateModal}
          id={
            selectedRowKeys?.length > 0
              ? selectedRowKeys?.map((f) => f?.id)
              : edit?.key
          }
          delete={activateUser}
          get={getUsers}
        />
      </Modal>

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        footer={null}
        centered={true}
        keyboard={false}
        onCancel={() => setDeleteModal(false)}
        maskClosable={false}
      >
        <DeleteConfirmation
          fromDelete={true}
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          delete={deleteTeamMember}
          get={getTeamMember}
          pageSize={params.pageSize}
        />
      </Modal>
    </div>
  );
};

export default Team;
