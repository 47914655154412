import { Button, Col, Dropdown, Form, Image, Modal, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../Images/Users/editIcon.svg";
import AddPartner from "../../components/Add/AddPartner";
import EditPartner from "../../components/Edit/EditPartner";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import Filter from "../../components/Layout/FilterPartner";
import TopBar from "../../components/Layout/TopBar";
import EditDots from "../../components/common/EditDots";
import FilterSearch from "../../components/common/FilterSearch";
import { deletePartners, getPartners } from "../../features/partnerSlice";
import css from "../../styles/Partners/partners.module.scss";

const Partners = () => {
  // TODO: fetch partners data
  const [filteredData, setFilteredData] = useState();
  const [sort, setSort] = useState();

  const dispatch = useDispatch();
  // TODO: get faq data when render
  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });
  useEffect(() => {
    dispatch(getPartners({ params, filter: filteredData, sort }));
  }, [dispatch, filteredData, params, sort]);

  const [form] = Form.useForm();

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [showData, setShowData] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setEditModal(false);
    setIsModalOpen(false);
    setFilterModal(false);
    setShowData(!showData);
  };

  const [edit, setEdit] = useState({
    key: 0,
    toggle: false,
  });

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  const start = (key) => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      alert(key);
      setLoading(false);
    }, 1000);
  };

  // dropdown functions
  function handleMenuClick(e) {
    start(e.key);
  }

  /* dropdown action */

  const items = [
    {
      label: "Send Email",
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menu = {
    items,
    onClick: handleMenuClick,
  };

  let partnerData = useSelector((state) => state.partners.partners);
  let isLoading = useSelector((state) => state.partners.loading);

  let tableData = partnerData?.data?.map((data, index) => {
    return { ...data, key: index + 1 };
  });
  // handle search
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const fields = ["name", "email", "category", "phone"];

  if (search) {
    const filteredData = partnerData?.data?.filter((data) => {
      return fields.some((field) =>
        data?.attributes[field]?.toLowerCase().includes(search.toLowerCase())
      );
    });
    tableData = filteredData;
  }

  let dataSource = partnerData?.data?.map((f, i) => ({
    id: f?.id,
    name: f?.attributes?.name,
    logoUrl: f?.attributes?.logo?.data?.attributes?.url,
    phone: f?.attributes?.phone,
    email: f?.attributes?.email,
    category: f?.attributes?.type,
    URL: f?.attributes?.website,
    map: f?.attributes?.website,
    createdAt: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredData(filters);
    if (sorter?.order) {
      setSort(
        `${sorter?.columnKey}:${sorter?.order === "ascend" ? "asc" : "desc"}`
      );
    }
  };

  const columns = [
    {
      title: "Organization Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },

    {
      title: "Logo Image",
      key: "logoUrl",
      render: (row) => {
        return (
          <Image
            loading={isLoading}
            src={row?.logoUrl}
            alt="logo"
            width={100}
            height={40}
            style={{ objectFit: "contain" }}
          />
        );
      },
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: [
        {
          text: "Business",
          value: "business",
        },
        {
          text: "Health",
          value: "health",
        },
        {
          text: "Social",
          value: "social",
        },
        {
          text: "Technical",
          value: "technical",
        },
        {
          text: "Media",
          value: "media",
        },
      ],
      onFilter: (value, row) => row?.category === value,
    },
    {
      title: "Organization URL",
      key: "URL",
      render: (row) => {
        return (
          <a href={row?.URL} target="_blank">
            {row?.URL}
          </a>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },

    {
      title: "Action",
      render: (row) => {
        return (
          <EditDots
            icon={editIcon}
            setEdit={setEdit}
            row={row}
            edit={edit}
            setEditModal={setEditModal}
            setDeleteModal={setDeleteModal}
          />
        );
      },
    },
  ];

  return (
    <div>
      <TopBar topic={"Partners"} link="category" category={true} />
      <div className={css.partnerContent}>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col>

          {/* search filter and add new */}
          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Add Partner"
            show={true}
            filterModal={filterModal}
            content={
              <Filter
                setFilterModal={setFilterModal}
                filterModal={filterModal}
                setFilteredData={setFilteredData}
              />
            }
            icon={<AiOutlineUserAdd className={css.filterIcon} />}
          />
        </Row>

        {/* table here */}

        <Table
          onChange={handleTableChange}
          scroll={{
            x: 1500,
          }}
          loading={isLoading}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          bordered
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            current: params.page,
            total: partnerData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>
      {/* modal */}
      <Modal
        maskClosable={false}
        open={isModalOpen}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <AddPartner setIsModalOpen={setIsModalOpen} form={form} />
      </Modal>

      <Modal
        open={editModal}
        footer={null}
        className={css.modalSignup}
        centered={true}
        keyboard={true}
        onCancel={handleCancel}
      >
        <EditPartner
          setEditModal={setEditModal}
          id={edit?.key}
          form={form}
          showData={showData}
          setShowData={setShowData}
        />
      </Modal>

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        footer={null}
        centered={true}
        keyboard={true}
        onCancel={() => setDeleteModal(false)}
      >
        <DeleteConfirmation
          fromDelete={true}
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          delete={deletePartners}
          get={getPartners}
        />
      </Modal>
    </div>
  );
};

export default Partners;
