import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { api } from "../Api";
import { BASEURL } from "../Url";
import { SendPushNotification } from "../components/SendPushNotification";

const qs = require("qs");

const initialState = {
  loading: false,
  settings: "",
  notificationsetting: [],
};

export const updateSiteSetting = createAsyncThunk(
  "updateSiteSetting",
  async (formData) => {
    const res = await api.put(`${BASEURL}/app-setting/?populate=*`, formData);
    return res.data;
  }
);
export const getLogo = createAsyncThunk("getLogo", async () => {
  const res = await api.get(`${BASEURL}/app-setting/?populate=*`);
  return res.data;
});

export const sendNotification = createAsyncThunk(
  "sendNotification",
  async (data) => {
    const res = await api.post(`${BASEURL}/notifications/createByGroup`, {
      data,
    });

    if (data?.groups?.includes("Donors")) {
      SendPushNotification(
        `/topics/donor_push_notification`,
        data.title,
        data.description,
        "HomeIndex"
        // paramId
      );
    }

    if (data?.groups?.includes("Users")) {
      SendPushNotification(
        `/topics/verified_user_push_notification`,
        data.title,
        data.description,
        "HomeIndex"
        // paramId
      );
    }

    if (data?.groups?.length === 3) {
      SendPushNotification(
        `/topics/user_push_notification`,
        data.title,
        data.description,
        "HomeIndex"
        // paramId
      );
    }
    // return res.data;
  }
);

export const getNotification = createAsyncThunk(
  "getNotification",
  async ({ params, filter }) => {
    const query = qs.stringify({
      pagination: params,
      populate: "*",
      filters: { isRead: filter?.isRead },
    });
    try {
      const res = await api.get(`${BASEURL}/notifications/?${query}`);
      return res.data;
    } catch (error) {
      console.log(error, "errror");
    }
  }
);

const appSettingSlice = createSlice({
  name: "appSettingSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(updateSiteSetting.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateSiteSetting.fulfilled, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Setting updated",
        confirmButtonColor: "#4BB543",
        text: "Settings are successfully updated",
      });
    });
    builder.addCase(updateSiteSetting.rejected, (state, action) => {
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    });
    builder.addCase(getLogo.fulfilled, (state, action) => {
      state.settings = action.payload;
    });

    builder.addCase(getNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotification.fulfilled, (state, action) => {
      state.loading = false;
      state.notificationsetting = action.payload;
    });
    builder.addCase(getNotification.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    });

    builder.addCase(sendNotification.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendNotification.fulfilled, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "success",
        title: "Notification sent",
        confirmButtonColor: "#4BB543",
        text: "Notifiction is successfully sent",
      });
    });
    builder.addCase(sendNotification.rejected, (state, action) => {
      state.loading = false;
      Swal.fire({
        icon: "error",
        title: "Opps...",
        text: action.error.message,
        confirmButtonColor: "#B00020",
      });
    });
  },
});

export default appSettingSlice.reducer;
