import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Layout/Navbar";
import { getMe } from "../features/userSlice";

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getMe(navigate));
  }, [dispatch]);

  const userMe = useSelector((state) => state.users.self);

  localStorage.setItem("name", userMe?.username);
  localStorage.setItem("role", userMe?.role?.name);
  localStorage.setItem("avatarUrl", userMe?.avatar?.url);

  return (
    <div>
      <Navbar />
    </div>
  );
};

export default HomePage;
