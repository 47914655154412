import { Button, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReferDonorCount } from "../../features/badgeSlice";
import { verifyDonorProfile } from "../../features/IDVerificationSlice";
import { getBadges } from "../../features/rewardSlice";
import { updateUserPhone } from "../../features/userSlice";
import css from "../../styles/addModal.module.scss";

const VerifyDonor = (props) => {
  const users = props.allUserInfo;
  const dispatch = useDispatch();

  // console.log(props.refId, "ffffsdf")

  useEffect(() => {
    dispatch(getBadges());

    if (props?.refId) {
      dispatch(getReferDonorCount(props.refId));
    }
  }, []);

  const badges = useSelector((state) => state.reward.badges);
  const donorReferCount = useSelector((state) => state.badge.donorReferCount);

  const referBadges = badges?.filter(
    (item) => item?.attributes?.metaData?.related === "Refer Donors"
  );

  const handleCancel = () => {
    props.setVerifyModal(false);
  };

  const handleOk = () => {
    if (props.name) {
      dispatch(
        updateUserPhone({
          id: props.id,
          data: {
            phoneVerified: !users.phoneVerified,
            getAll: props.reloadall,
          },
        })
      );
    } else {
      dispatch(
        verifyDonorProfile({
          event: props.event,
          orgFollowers: props.orgFollowers,
          verificationNotes: props.verificationNotes,
          Id: props.id,
          path: props.path,
          getAll: props.getAll,
          badges,
          userId: props.userId,
          body: props.body,
          screen: props.screen,
          paramId: props.paramId,
          eventJoiner: props.eventJoiner,
          referBadges,
          donorReferCount,
          donorProfile: props.donorProfile,
          refId: props.refId,
          eventName: props.eventName,
          updatedAt: props.updatedAt,
          eventJoiners: props.eventJoiners,
          userSettings: props.userSettings,
        })
      );
    }
    props.setVerifyModal(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "2rem",
      }}
    >
      <h6>Are you sure you want to verify?</h6>

      <Row justify={"end"} style={{ gap: "1rem" }}>
        <Button danger onClick={handleCancel} className={css.cancelButton}>
          Cancel
        </Button>
        <Button onClick={handleOk} type="primary">
          Verify
        </Button>
      </Row>
    </div>
  );
};

export default VerifyDonor;
