import { Button, Col, Dropdown, Modal, Popover, Row, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterSearch from "../../components/common/FilterSearch";
import BloodNeedCard from "../../components/Layout/BloodNeedCard";
import DeleteConfirmation from "../../components/Layout/DeleteConfirmation";
import FilterDonor from "../../components/Layout/FilterDonor";
import TopBar from "../../components/Layout/TopBar";
import SendEmail from "../../components/SendEmail/SendEmail";
import { getVerifiedDonor } from "../../features/dashboardSlice";
import {
  deleteDonorList,
  getActiveDonorCount,
  getAllDonorList,
  getDonorList,
} from "../../features/donorListSlice";
import { deactivateUser } from "../../features/userSlice";
import icon3 from "../../Images/BloodNeeds/activeDonors.svg";
import icon1 from "../../Images/BloodNeeds/allNeeds.svg";
import icon4 from "../../Images/BloodNeeds/inActiveDonors.svg";
import icon2 from "../../Images/BloodNeeds/verifiedNeeds.svg";
import css from "../../styles/DonorList/donorList.module.scss";

const DonorList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState();
  const [sort, setSort] = useState();

  const [params, setParams] = useState({
    page: 1,
    pageSize: 8,
  });

  useEffect(() => {
    dispatch(getActiveDonorCount());
    dispatch(getVerifiedDonor({ status: "Verified" }));
    dispatch(getAllDonorList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDonorList({ params, filter: filteredData, sort }));
  }, [dispatch, filteredData, params, sort]);

  // useEffect(() => {
  //   dispatch(getDonorList);
  // });
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [deactivate, setDeactivateModal] = useState(false);

  const [edit, setEdit] = useState({
    key: 0,
  });

  // bring user data from store
  let donorListData = useSelector((state) => state.donorList.donorList);
  let alldonorListData = useSelector((state) => state.donorList.totalDonor);

  let activeDonor = useSelector(
    (state) => state.donorList.cardValues.activeDonors
  );
  let verifiedDonor = useSelector((state) => state.dashboard.verifiedDonor);

  let isLoading = useSelector((state) => state.donorList.loading);

  // handle search
  const [search, setSearch] = useState("");

  const [emailBox, setEmailBox] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  //row selection
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.deactivate,
    }),
  };

  const [hasSelected, setHasSelected] = useState(false);
  useEffect(() => {
    setHasSelected(selectedRowKeys.length > 0);
  }, [selectedRowKeys?.length]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [params]);

  /* dropdown action */
  const items = [
    {
      label: (
        <Popover
          open={emailBox}
          content={
            <SendEmail
              emails={selectedRowKeys?.map(
                (f) => f?.attributes?.user?.data?.attributes?.email
              )}
              open={setEmailBox}
              setSelectedRowKeys={setSelectedRowKeys}
            />
          }
          showArrow={false}
          placement="topLeft"
          autoAdjustOverflow={true}
          destroyTooltipOnHide={true}
          overlayStyle={{
            width: "30%",
          }}
        >
          <span onClick={() => setEmailBox(true)}>Send Email</span>
        </Popover>
      ),
      key: "1",
    },
    {
      label: <span onClick={() => setDeactivateModal(true)}>Deactivate</span>,
      key: "2",
    },
  ];
  const menu = {
    items,
  };

  const fields = [
    "username",
    "bloodGroup",
    "gender",
    "phone",
    "age",
    "location",
  ];
  let dataSource = donorListData?.data?.map((f, i) => ({
    key: f,
    id: f?.id,
    firstName: `${f?.attributes?.user?.data?.attributes?.firstName} ${f?.attributes?.user?.data?.attributes?.lastName}`,
    avatarUrl: f?.avatar?.url,
    bloodGroup: f?.attributes?.bloodGroup?.data?.attributes?.name,
    gender: f?.attributes?.gender,
    provider: f?.attributes?.user?.data?.attributes?.provider,
    phone: f?.attributes?.user?.data?.attributes?.phone,
    location: `${f?.attributes?.user?.data?.attributes?.currentAddress?.city} -${f?.attributes?.user?.data?.attributes?.currentAddress?.wardNo} , ${f?.attributes?.user?.data?.attributes?.currentAddress?.district}`,
    date: f?.attributes?.questionares?.lastDonatedDate
      ? moment
          .utc(f?.attributes?.questionares?.lastDonatedDate)
          .format("YYYY-MM-DD")
      : "-",
    status: f?.attributes?.status,
    createdAt: moment.utc(f?.attributes?.createdAt).format("YYYY-MM-DD"),
    active:
      f?.attributes?.user?.data?.attributes?.userSetting?.availableForDonation,
  }));

  if (search) {
    const filteredData = dataSource?.filter((data) => {
      return fields.some((field) =>
        String(data?.[field])?.toLowerCase().includes(search.toLowerCase())
      );
    });
    dataSource = filteredData;
  }

  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredData(filters); // Store the filter info
    if (sorter?.order) {
      if (sorter?.columnKey === "date") {
        setSort(
          `questionaries.lastDonatedDate:${
            sorter?.order === "ascend" ? "asc" : "desc"
          }`
        );
      } else if (sorter?.columnKey === "firstName") {
        setSort(
          `user.firstName:${sorter?.order === "ascend" ? "asc" : "desc"}`
        );
      } else {
        setSort(
          `${sorter?.columnKey}:${sorter?.order === "ascend" ? "asc" : "desc"}`
        );
      }
    }
  };

  const columns = [
    {
      title: "Name",
      key: "firstName",
      render: (row) => {
        return (
          <div onClick={() => navigate(`${row?.id}`)}>{row?.firstName}</div>
        );
      },
      minWidth: "15vw",
      sorter: true,
    },
    {
      title: "Blood Group",
      dataIndex: "bloodGroup",
      key: "bloodGroup",
      filters: [
        {
          text: "A+",
          value: "A+",
        },
        {
          text: "B+",
          value: "B+",
        },
        {
          text: "O+",
          value: "O+",
        },
        {
          text: "AB+",
          value: "AB+",
        },
        {
          text: "A-",
          value: "A-",
        },
        {
          text: "B-",
          value: "B-",
        },
        {
          text: "O-",
          value: "O-",
        },
        {
          text: "AB-",
          value: "AB-",
        },
      ],
      onFilter: (value, record) => record?.bloodGroup === value,
    },
    {
      title: "Provider",
      key: "provider",
      width: "15%",
      dataIndex: "provider",
      filters: [
        {
          text: "Google",
          value: "google",
        },
        {
          text: "Facebook",
          value: "facebook",
        },
        {
          text: "Apple",
          value: "apple",
        },
        {
          text: "Local",
          value: "local",
        },
      ],
      filteredValue: filteredData?.provider || null, // Persist filter state
      onFilter: (value, record) => true, // No filtering logic needed here, just return true
    },

    {
      title: "Phone No.",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },

    {
      title: "Registered at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true, // Sorting function for dates
      sortDirections: ["ascend", "descend"], // Ascending and descending order
    },

    {
      title: "Active",
      key: "active",
      render: (row) => {
        return (
          <>
            {row?.active && row?.status === "Verified" ? (
              <span className={css.activeDonor}>Active</span>
            ) : (
              <span className={css.inactiveDonor}>Inactive</span>
            )}
          </>
        );
      },
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      onFilter: (value, row) => row?.active === value,
    },

    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Last Donated",
      dataIndex: "date",
      key: "date",
      sorter: true, // Sorting function for dates
      sortDirections: ["ascend", "descend"], // Ascending and descending order
    },

    {
      title: "Status",
      key: "status",
      render: (row) => {
        return (
          <>
            {row?.status === "Verified" ? (
              <span className={css.activeDonor}>Verified</span>
            ) : (
              <span className={css.inactiveDonor}>Pending</span>
            )}
          </>
        );
      },
      filters: [
        {
          text: "Verified",
          value: "Verified",
        },
        {
          text: "Unverified",
          value: "Pending",
        },
      ],
      onFilter: (value, row) => row?.status === value,
    },

    // {
    //   title: "Action",
    //   render: (row) => {
    //     return (
    //       <EditDots
    //         icon={editIcon}
    //         setEdit={setEdit}
    //         row={row}
    //         edit={edit}
    //         setEditModal={setEditModal}
    //         setDeleteModal={setDeleteModal}
    //         from={"donorList"}
    //         link="/dashboard/edit-donor-list"
    //       />
    //     );
    //   },
    // },
  ];

  return (
    <div>
      <TopBar topic={"Donors"} />
      <div className={css.donorListContent}>
        <Row
          gutter={[16, 16]}
          justify="space-between"
          className={css.cardContainer}
        >
          <Col xs={24} sm={11} md={5} className={css.card}>
            <BloodNeedCard
              value={donorListData?.meta?.pagination?.total}
              icon={icon1}
              title="Total Donors"
            />
          </Col>
          <Col xs={24} sm={11} md={5} className={css.card}>
            <BloodNeedCard
              value={verifiedDonor}
              icon={icon2}
              title="Verified Donors"
            />
          </Col>
          <Col xs={24} sm={11} md={5} className={css.card}>
            <BloodNeedCard
              value={activeDonor?.meta?.pagination?.total}
              icon={icon3}
              title="Active Donors"
            />
          </Col>
          <Col xs={24} sm={11} md={5} className={css.card}>
            <BloodNeedCard
              value={
                alldonorListData?.meta?.pagination?.total -
                activeDonor?.meta?.pagination?.total
              }
              icon={icon4}
              title="Inactive Donors"
            />
          </Col>
        </Row>

        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Col>
            <Dropdown menu={menu} disabled={!hasSelected}>
              <Button
                loading={loading}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                Actions <BsChevronDown />
              </Button>
            </Dropdown>
          </Col>

          {/* search filter and add new */}

          <FilterSearch
            handleSearch={handleSearch}
            setFilterModal={setFilterModal}
            setIsModalOpen={setIsModalOpen}
            title="Add Donor"
            show={false}
            filterModal={filterModal}
            content={
              <FilterDonor
                setFilteredData={setFilteredData}
                setFilterModal={setFilterModal}
                filterModal={filterModal}
              />
            }
            icon={<AiOutlineUserAdd className={css.filterIcon} />}
          />
        </Row>
        {/* table here */}
        <Table
          onChange={handleTableChange}
          scroll={{
            x: 1500,
          }}
          loading={isLoading}
          rowSelection={rowSelection}
          dataSource={dataSource}
          bordered
          columns={columns}
          pagination={{
            showTitle: false,
            responsive: true,
            prevIcon: <GrLinkPrevious />,
            nextIcon: <GrLinkNext />,
            defaultCurrent: 1,
            // current: params.page,
            total: donorListData?.meta?.pagination?.total,
            pageSize: params.pageSize,
            onChange: (page) => {
              setParams({
                ...params,
                page,
              });
            },
          }}
        />
      </div>
      {/* modal */}

      <Modal
        title={"Delete Confirmation"}
        open={deleteModal}
        footer={null}
        maskClosable={false}
        centered={true}
        keyboard={true}
        onCancel={() => setDeleteModal(false)}
      >
        <DeleteConfirmation
          setDeleteModal={setDeleteModal}
          id={edit?.key}
          delete={deleteDonorList}
          get={getDonorList}
          fromDelete={true}
        />
      </Modal>
      <Modal
        title={"Deactivate Confirmation"}
        open={deactivate}
        footer={null}
        centered={true}
        maskClosable={false}
        keyboard={false}
        onCancel={() => setDeactivateModal(false)}
      >
        <DeleteConfirmation
          fromDeactivate={true}
          setDeleteModal={setDeactivateModal}
          id={
            selectedRowKeys?.length > 0
              ? selectedRowKeys?.map((f) => f?.id)
              : edit?.key
          }
          delete={deactivateUser}
          get={getDonorList}
        />
      </Modal>
    </div>
  );
};

export default DonorList;
